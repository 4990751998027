.bareMetal_ui {
  // normalize box model
  .content-only {
    *,
    *:before,
    *:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  @import "constants";

  //Color definitions
  @import "shared";
  color: $black;
  font-family: "Open Sans", sans-serif;

  button {
    background: #e9a324;
    box-shadow: 0 4px 0 darken(#e9a324, 20%);
    color: #242424;

    &:hover,
    &:focus {
      background: lighten(#E9A324, 5%);
      box-shadow: 0 4px 0 darken(#e9a324, 10%);
    }
  }

  .title-icon {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-image: url("/images/bareMetal/icons.png");
    background-size: cover;

    &--cpu {
      background-position: 0 0;
    }

    &--ram {
      background-position: 0 -50px;
    }

    &--storage {
      background-position: 0 -100px;
    }

    &--network {
      background-position: 0 -150px;
    }

    &--software {
      background-position: 0 -200px;
    }
  }

  .excludingvat {
    font-size: 10px;
  }

  .raid_text {
    font-size: 75%;
  }

  .help {
    color: #333;
    border-bottom: 1px dotted #333;
    font-size: 10px;
    line-height: 1;
    cursor: default;
    cursor: help;
    text-transform: none;
    font-family: "Open Sans", sans-serif;
  }
  #content-only h2 {

    //selector altered to override external rules
    font-size: 22px;
    margin: 1em 0 0.8em !important;

    //important to override extarnal rules
  }
  #noCPanel,
  #noBUps,
  #noCPanel2 {
    color: $higreen;
    cursor: pointer;
  }
  #loading {
    margin: 30px auto;
    display: block;
  }
  #order_panel {
    display: none;
     &:after {
       display: table;
       content: "";
       clear: both;
     }
  }
  label {
    cursor: pointer;
  }
  .ticks {
    width: 100%;
    height: 20px;
    position: relative;
    margin-top: 25px;
    font-weight: bold;
    &._2 {
      .tick {
        &:nth-child(1) {
          left: 11px;
        }
        &:nth-child(2) {
          left: calc(99.99% - 9px);
        }
      }
    }
    &._3 {
      .tick {
        &:nth-child(1) {
          left: 11px;
        }
        &:nth-child(2) {
          left: calc(50% + 1px);
        }
        &:nth-child(3) {
          left: calc(99.99% - 9px);
        }
      }
    }
    &._4 {
      .tick {
        &:nth-child(1) {
          left: 11px;
        }
        &:nth-child(2) {
          left: calc(33.33% + 4px);
        }
        &:nth-child(3) {
          left: calc(66.66% - 3px);
        }
        &:nth-child(4) {
          left: calc(99.99% - 9px);
        }
      }
    }
    &._5 {
      .tick {
        &:nth-child(1) {
          left: 11px;
        }
        &:nth-child(2) {
          left: calc(25% + 5px);
        }
        &:nth-child(3) {
          left: 50%;
        }
        &:nth-child(4) {
          left: calc(75% - 5px);
        }
        &:nth-child(5) {
          left: calc(99.9% - 9px);
        }
      }
    }
    &._6 {
      .tick {
        &:nth-child(1) {
          left: 11px;
        }
        &:nth-child(2) {
          left: 20%;
        }
        &:nth-child(3) {
          left: 40%;
        }
        &:nth-child(4) {
          left: 60%;
        }
        &:nth-child(5) {
          left: 80%;
        }
        &:nth-child(6) {
          left: calc(99.9% - 9px);
        }
      }
    }
    .tick {
      width: 50px;
      margin-left: -25px;
      text-align: middle;
      position: absolute;
      text-align: center !important;
      display: inline-block;
      &::before {
        content: " ";
        border-left: 1px solid $grey;
        position: absolute;
        top: -15px;
        left: calc(50% - 1px);
        height: 12px;
      }
    }
  }

  #storageBar,
  #RAMBar {
    background: #999;
    position: relative;
    height: 6px;
    cursor: pointer;
    .handle {
      border: 1px solid #bd7900;
      background: #feca32;
      background: -moz-linear-gradient(top, #feca32 0, #e9a324 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FECA32), color-stop(100%, #E9A324));
      background: -webkit-linear-gradient(top, #FECA32 0, #E9A324 100%);
      background: -o-linear-gradient(top, #feca32 0, #e9a324 100%);
      background: -ms-linear-gradient(top, #feca32 0, #e9a324 100%);
      background: linear-gradient(to bottom, #FECA32 0, #E9A324 100%);
      filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#feca32', endColorstr='#e9a324', GradientType=0);
      -webkit-transition: left 0.3s ease;
      -moz-transition: left 0.3s ease;
      transition: left 0.3s ease;
      position: absolute;
      top: -8px;
      width: 18px;
      height: 18px;
      border-radius: 20px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      z-index: 10;
    }
  }

  #storageBar.no-slider,
  #RAMBar.no-slider {
    background: none;
    box-shadow: none;
    height: 0;
  }

  #addedToBasket {
    margin: 50px 0;
    display: none;
    text-align: center;
    button {
      margin: 10px;
    }
    p {
      font-size: 22px;
    }
  }
  p.chosen {
    color: $black;
    font-weight: bold;
    font-size: 22px;
    margin: 15px 0;
/*     span {
  color: $gray;
  font-size: 12px;
  margin-left: -60px;
  font-weight: normal;
  display: inline-block;
  width: 60px;
  position: relative;
  left: 60px;
} */
  }
  #order_panel {
    position: relative;
  }
  p.title {
    position: relative;
    font-weight: normal;
    text-transform: uppercase;
    border-radius: 4px 4px 0 0;
    border: 1px solid $lgray;
    font-size: 20px;
    font-family: "dinmedium";
    margin: -16px -16px 15px;
    color: #2d2d2d;
    text-align: center;
    line-height: 42px;
    padding: 5px 0;
    background: #fff;
    overflow: hidden;
    line-height: 50px;

    // height of .icon
    .title-icon {
      float: left;
      margin-left: 10px;
      margin-right: -60px;

      // width of icon + margin keeps the text centred
    }

    .help {
      position: absolute;
      top: 23px;
      right: 10px;
    }
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  #configuration {
    float: left;
    width: 70%;
    padding-right: 10px;
    > div {
      border-radius: 4px;
      margin: 10px 0 20px;
      vertical-align: top;
      padding: 15px;
      background: $vlgray;
      border: 1px solid $lgray;
      text-align: center;
      height: calc(100% - 62px);
      .coreType {
        .options {
          white-space: nowrap;
          text-align: center;
        }
        li {
          display: inline-block;
          margin: 6px;
          width: calc(33.33% - 12px);
          font-size: 18px;
          position: relative;

          &:after {
            position: absolute;
            content: "";
            top: -7px;
            right: -7px;
            background-image: url('/images/bareMetal/cpu-ribbons.png');
            background-repeat: no-repeat;
            width: 116px;
            height: 70px;
            overflow: hidden;
          }

          &:nth-child(2) {
            &:after {
              background-position: 0 -70px;
            }
          }
          &:nth-child(3) {
            &:after {
            background-position: 0 -140px;
            }
          }
        }
        input {
          margin-bottom: 8px;
        }
        p {
          color: $black;
          margin: 0;
          font-weight: bold;
          font-size: 16px;
          &.small {
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
      .storage {
        .optionGroups {
          white-space: nowrap;
          h4 {
            margin-bottom: 8px;
            text-transform: uppercase;
            font-family: "dinmedium";
            color: $black;
          }
          > div {
            display: inline-block;
            vertical-align: middle;
            margin: 15px 0;
            padding: 0 15px;
            width: 33.33%;
            &:not(:first-child) {
              border-left: 1px solid $lgray;
            }
          }
        }
        .usable-space {
          font-size: .5em;
        }
      }
      .network {
        .optionGroups > div {
          display: inline-block;
          vertical-align: middle;
          margin: 15px 0;
          padding: 0 15px;

          &:not(:first-child) {
            border-left: 1px solid $lgray;
          }
          > div {
            display: inline-block;
            margin: 5px 15px;
            min-width: 200px;
          }
        }
      }
      .software {
        .optionGroups {
          overflow: hidden;

          h4 {
            font-family: "dinmedium";
            text-transform: uppercase;
            color: $black;
          }

          > div {
            float: left;
            width: 50%;
            padding: 10px;
          }

          select {
            width: 100%;
            background: #EFEFEF;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            border: 1px solid #ABAAAA;
            height: 50px;
            line-height: 38px;
            position: relative;
            cursor: pointer;
            box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
            font-family: 'dinbold', sans-serif;
            font-size: 18px;
            margin-top: 15px;

            &:disabled {
              color: #BBB;
              box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
              cursor: default;
              cursor: not-allowed;
            }
          }
        }
      }
      .OSs {
      }
      .CPs {
        > div {
          width: 33.33%;
          float: left;
        }
      }
    }
  }
  #payment {
    width: 30%;
    margin-top: 10px;
    display: inline-block;
    vertical-align: top;
    padding: 15px;
    background: $vlgray;
    border: 1px solid $lgray;
    text-align: center;
    &._disabled {
      button.new {
        background: $gray;
        cursor: not-allowed;
        box-shadow: 0 4px 0 #333;
        &:active {
          position: static;
        }
      }
    }
    .chosen {
      margin: 35px 0 10px;
      color: #333;
      font-size: 30px;
      line-height: 1;
    }
    .setup-fee {
      margin-top: 10px;
      color: lighten(#333, 10%);
      font-size: 15px;
    }
    label {
      font-weight: bold;
      display: block;
      margin: 20px 0;
    }

    .selectable {
      display: inline-block;
      margin: 10px;
    }
    button {
      margin: 20px 0 10px;
      width: 180px;
      min-height: 70px;
      font-size: 20px;
      &:last-child {
        margin-top: 10px;
      }
    }
  }

  .selectable {
    opacity: 0.8;
    cursor: pointer;
    background: #efefef;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #abaaaa;
    border-radius: 5px;
    font-size: 16px;
    padding: 15px 10px;
    border: 1px solid #636363;
    white-space: nowrap;
    margin-top: 15px;

    &:not(.selected):hover {
      opacity: 1;
      background: lighten(#efefef, 5%);
    }
  }

  .small_text{
    font-size: 75%;
  }

  .selectable.selected {
    opacity: 1;
    color: #242424;
    background: #feca32;
    background: -moz-linear-gradient(top, #feca32 0, #e9a324 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FECA32), color-stop(100%, #E9A324));
    background: -webkit-linear-gradient(top, #FECA32 0, #E9A324 100%);
    background: -o-linear-gradient(top, #feca32 0, #e9a324 100%);
    background: -ms-linear-gradient(top, #feca32 0, #e9a324 100%);
    background: linear-gradient(to bottom, #FECA32 0, #E9A324 100%);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#feca32', endColorstr='#e9a324', GradientType=0);
    border: 1px solid #bd7900;
    cursor: default;
  }

  .selectable.Disks_2 {
    padding: 5px 5px;
  }

  .selectable.Disks_4 {
    padding: 5px 5px;
  }

  .not-selectable.Disks_2 {
    padding: 5px 5px;
  }

  .not-selectable.Disks_4 {
    padding: 5px 5px;
  }

  .not-selectable {
    font-size: 16px;
    padding: 15px 10px;
    border-radius: 5px;
    white-space: nowrap;
    margin-top: 15px;
    color: #bbb;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #abaaaa;
  }
  .not-selectable:hover {
    cursor: default;
    cursor: not-allowed;
  }
  .chosen {
    margin: 10px 0 22px;
  }

  // jQuery jTip tooltips
  .cluetip-default {
    background: #eee;
    border-bottom: 5px solid #1a527b;
    padding: 15px;
    box-shadow: 0 0 3px 3px #ccc;

    h3 {
      font-size: 1rem;
      font-weight: bold;
      text-align: left;

      &:first-of-type {
        margin-top: 0;
        padding-top: 0;
      }
    }

    p {
      font-size: 0.8rem;
      line-height: 1.4;
    }
  }
  span.link {
    text-decoration: underline;
  }
}
